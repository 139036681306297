import React from "react";
import Navigation from "../../components/navigation";

function ContactScreen() {
  return (
    <>
      <Navigation />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
        {/* Insert your contact form or information here */}
        <p>Contact information and form coming soon.</p>
      </div>
    </>
  );
}

export default ContactScreen;
